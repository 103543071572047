@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    pre {
        @apply w-full text-gray-400 p-4 overflow-auto bg-gray-800 rounded-md mb-4;
      }
      p{
        @apply  p-2 font-normal text-gray-500  dark:text-gray-400;
      }

     
          h2{
        @apply text-3xl font-extrabold text-white p-2;
      }
          h3{
        @apply text-2xl font-extrabold text-white;
      }
          h4{
        @apply text-xl font-extrabold text-white;
      }
          h5{
        @apply text-xl font-extrabold text-white;
      }
    
      ol{
        @apply text-white list-decimal p-7 ;
      }
      strong{
        @apply text-indigo-500;
      }
      a{
        @apply text-indigo-500 cursor-pointer;
      }
       


}